import React from 'react'

import Button from 'react-bootstrap/Button'
import { ControlledCarousel } from '../ui/Carousel'
import ContentMain from '../ui/ContentMain'
import string from '../../assets/strings'

import './Empresa.scss'
import Container from 'react-bootstrap/Container';
import { FaFortAwesome } from "react-icons/fa";
import { MdBusiness, MdVisibility } from "react-icons/md";
import { FaGraduationCap } from "react-icons/fa";
import Divider from '../Divider/Divider';


const Empresa = () => (
  <Container className="mt-3">
      <section>
        <h2 class="h1 py-2 font-weight-bold text-center empresa__title">Um pouco da nossa história</h2>  
        <div class="container-fluid empresa-container">
          <div class="row">
            <div class="col-sm-12 col-md-11 empresa-container">
              <div className="row thf-mb-4">
                <div className="col-sm-12">
                  {/* <h4>A Empresa</h4> */}
                  <Divider title="Empresa" />
                  <div>
                    <MdBusiness  className="home-icon empresa-icon__primary"/>
                  </div>
                  <p className="home-paragrafo">
                    {`${string.empresa.descri01} ${string.empresa.descri02} ${string.empresa.descri03}`}
                  </p>
                </div>
              </div>

              <Divider title="Visão" />
              <div>
                <MdVisibility  className="home-icon empresa-icon__primary"/>
              </div>
              <p className="home-paragrafo">{string.empresa.nossaVisao01}</p>

              {/* <h4>Política de Qualidade</h4> */}
              <Divider title="Qualidade" />
              
              <div>
                <FaGraduationCap  className="home-icon empresa-icon__primary"/>
              </div>              
              <p className="home-paragrafo">{string.empresa.politica}</p>

            </div>
          </div>
        </div>
    </section>
  </Container>     

);

export default Empresa;