import React from 'react';

import ContentMain from '../ui/ContentMain';
import Container from 'react-bootstrap/Container';
import { FaRegBuilding, FaCubes, FaHandshake, FaCog } from 'react-icons/fa';
import './Servicos.scss'

const Servicos = () => (
    <Container className="mt-3">
      <div class="row">
        <div class="col-sm-12 col-md-10 servico-container">
          <h2 class="font-weight-bold text-center">Nossos Serviços</h2>
          <p class="mb-5 pb-3 lead grey-text text-justify">Os serviços executados pela Engteck são planejados de forma criteriosa, desta forma podemos oferecer as melhores soluções. Para isso, os projetos ficam sob a inteira supervisão e responsabilidade de Engenheiros e Técnicos com vasta experiência no setor elétrico.</p>

          <div class="row">

            <div class="col-md-6">
              <div class="row mb-2">
                <div class="col-1">
                  <FaCog />
                </div>
                <div class="col-10 text-left">
                  <h5 class="font-weight-bold"> Paineis TTA</h5>
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-1">
                  <FaCog />
                </div>
                <div class="col-10 text-left">
                  <h5 class="font-weight-bold">Painéis Gerais (QGBT).</h5>
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-1">
                  <FaCog />
                </div>
                <div class="col-10 text-left">
                  <h5 class="font-weight-bold">Painéis de Automação e Sinalização</h5>
                </div>
              </div>
            </div>

            <div class="col-md-6">

              <div class="row mb-2">
                <div class="col-1">
                  <FaCog />
                </div>
                <div class="col-10 text-left">
                  <h5 class="font-weight-bold">Painéis de Distribuição.</h5>
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-1">
                  <FaCog />
                </div>
                <div class="col-10 text-left">
                  <h5 class="font-weight-bold">Painéis para canteiros de obras</h5>
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-1">
                  <FaCog />
                </div>
                <div class="col-10 text-left">
                  <h5 class="font-weight-bold">Painéis para Acionamento de Motores</h5>
                </div>
              </div>

            </div>

          </div>

        </div>
      </div>
    </Container>
);

export default Servicos;