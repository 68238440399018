import React from 'react';
import ContentMain from '../ui/ContentMain';
import './Parceiros.scss'
import Container from 'react-bootstrap/Container';
import images from '../../assets/images/images'

import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


const Parceiros = () => (
    <Container className="mt-3">
      <div className="container-fluid text-center">
        <h2  className="font-weight-bold">Nossos Parceiros</h2>
        <div className="row">
          {/* <div className="col-sm-4 thf-mt-3">
                <Image src={images.carthoms} fluid className="parceiros-img"/>
          </div> */}

          <div className="col-sm-4 thf-mt-3">
            <Image src={images.weg} fluid className="parceiros-img"/>
          </div>

          {/* <div className="col-sm-4 thf-mt-3">
            <Image src={images.srSenador} fluid className="parceiros-img"/>
          </div>

          <div className="col-sm-4 thf-mt-3">
            <Image src={images.cemar} fluid className="parceiros-img"/>
          </div>

          <div className="col-sm-4 thf-mt-3">
            <Image src={images.hellermannTyton} fluid className="parceiros-img"/>
          </div>

          <div className="col-sm-4 thf-mt-3">
            <Image src={images.siemens} fluid className="parceiros-img"/>
          </div>

          <div className="col-sm-4 thf-mt-3">
            <Image src={images.phoenixContact} fluid className="parceiros-img"/>
          </div>

          <div className="col-sm-4 thf-mt-3">
            <Image src={images.abb} fluid className="parceiros-img"/>
          </div>

          <div className="col-sm-4 thf-mt-3">
            <Image src={images.schmersal} fluid className="parceiros-img"/>
          </div>           */}

        </div>
      </div>
    </Container>
    
);

export default Parceiros;