import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import { Link, NavLink } from 'react-router-dom';

import './HeaderNav.scss';
import logo from '../../../../assets/images/EngTeck-100px.png';
import { equipamentos } from '../../../../enums/equipamentos';
import { Container, Grid } from '@material-ui/core';

const MENU_ITEMS = [
  { to: 'home', label: 'Home' },
  { to: 'empresa', label: 'Empresa' },
  { to: 'servicos', label: 'Serviços' },
  { to: 'parceiros', label: 'Parceiros' },
  { to: 'contatos', label: 'Contatos' },
  { to: `galeria/${'todas'}`, label: 'Galeria' },
];

class HeaderNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
    };

    this.showHide = this.showHide.bind(this);
  }

  showHide() {
    this.setState({ showMenu: !this.state.showMenu });
  }

  render() {
    return (
      <Navbar
        className='header__navbar'
        collapseOnSelect
        expand='lg'
        bg='light'
        variant='light'
        fixed='top'
      >
        <Container maxWidth='lg'>
          <Grid container justify='space-between' alignItems='center'>
            <Grid item>
              <Navbar.Brand href='#home'>
                <Link clLinkss='navbar-brand' to='/'>
                  <picture className='header-nav__logo'>
                    <img
                      src={logo}
                      className='img-fluid header-nav__logo'
                      alt='Logo EngTeck'
                    />
                  </picture>
                </Link>
              </Navbar.Brand>
            </Grid>
            <Grid item>
              <Grid container>
                <Navbar.Collapse
                  id='responsive-navbar-nav'
                  className='menu-mob'
                >
                  <Nav className='header__nav-items'>
                    {MENU_ITEMS.map((item) => {
                      return (
                        <Grid item>
                          <Nav.Link href='#' className='header__nav-item'>
                            <NavLink
                              activeStyle={{
                                color: '#ff9a3f',
                              }}
                              to={`/${item.to}`}
                              className='nav-link header-nav__menu__link'
                            >
                              {item.label}
                            </NavLink>
                          </Nav.Link>
                        </Grid>
                      );
                    })}
                  </Nav>
                </Navbar.Collapse>
              </Grid>
            </Grid>
            <Grid item className='responsive-menu__icon responsive__tag'>
              <Navbar.Toggle aria-controls='responsive-navbar-nav' />
            </Grid>
          </Grid>
        </Container>
      </Navbar>
    );
  }
}

export default HeaderNav;

// <NavLink
//   to="/faq"
//   activeStyle={{
//     fontWeight: "bold",
//     color: "red"
//   }}
// >
