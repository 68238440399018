import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import './App.css';
import Header from './app/components/HeaderContainer/Header/Header';
import Footer from './app/components/Footer/Footer';
import Home from './app/components/Page/Home';
import Empresa from './app/components/Page/Empresa';
import Servicos from './app/components/Page/Servicos';
import Parceiros from './app/components/Page/Parceiros';
import Contatos from './app/components/Page/Contatos';
import Galeria from './app/components/Page/Galeria';

import { FiPhoneCall } from 'react-icons/fi';
import { Container } from '@material-ui/core';

// import { Home, Empresa, Header } from 'Components'

const App = () => {
  return (
    <Container maxWidth='lg'>
      <main className='main-container'>
        <Header />
        <div className='main-content'>
          <Switch>
            <Route exact path='/home' component={Home} />
            <Route path='/empresa' component={Empresa} />
            <Route path='/servicos' component={Servicos} />
            <Route path='/parceiros' component={Parceiros} />
            <Route path='/contatos' component={Contatos} />
            <Route path='/galeria/:equipamento' component={Galeria} />
            <Redirect from='*' to='/home' />
          </Switch>
        </div>
        <Footer />
        <a href='tel:+5511966407607' class='app-button-float'>
          <FiPhoneCall className='app-icon' />
        </a>
      </main>
    </Container>
  );
};

export default App;
