const images = {
  carthoms: require('./carthoms.jpg'),
  weg: require('./weg.png'),
  srSenador: require('./sr-senador.png'),
  cemar: require('./cemar.png'),
  hellermannTyton: require('./hellermann-tyton.jpg'),
  siemens: require('./siemens.png'),
  phoenixContact: require('./phoenix-contact.png'),
  abb: require('./abb.png'),
  schmersal: require('./schmersal_logo.jpg'),
  gallery: {
    todas: [],
    capacitor: [
      require('./gallery/img_banco_capacitor_01.jpg'),
      require('./gallery/img_banco_capacitor_02.jpg'),
    ],
    bomba: [
      require('./gallery/img_bomba_01.jpg'),
      require('./gallery/img_bomba_02.jpg'),
    ],
    ditribuição: [
      require('./gallery/img_distribuicao_01.jpg'),
      require('./gallery/img_distribuicao_02.jpg'),
      require('./gallery/img_distribuicao_03.jpg'),
      require('./gallery/img_distribuicao_04.jpg'),
      require('./gallery/img_distribuicao_05.jpg'),
      require('./gallery/img_distribuicao_06.jpg'),
      require('./gallery/img_distribuicao_07.jpg'),
      require('./gallery/img_distribuicao_08.jpg'),
    ],
    pressurizacão: [
      require('./gallery/img_pressurizacao_01.jpg'),
      require('./gallery/img_pressurizacao_02.jpg'),
    ],
    canteiro: [require('./gallery/img_canteiro_01.jpg')],
    geral: [require('./gallery/img_geral_01.jpg')],
  },
};

export default images;
