
import React from 'react'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

const  ModalCust = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            E-mail enviado com sucesso
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
          Obrigado por entrar em contato!
          </p>
          <p>Muito em breve um representante ira entrar em contato.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={props.onHide}>Fechar</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  
  export default ModalCust