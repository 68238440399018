import React, { Component } from 'react'

import './ContentMain.scss'

export default class ContentMain extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        const { children } = this.props
    return (
        <div className="content-main">
            <div className="content-main__container">
                {children}
            </div>
        </div>
    )
    }
}
