import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import './app/assets/styles/index.scss'
import 'bootstrap/dist/css/bootstrap.css';
import "@totvs/thf-theme/css/thf-theme-default.min.css";
// import App from './App';
// import App from './app/components/AppContainer/App/App';
// import App from './App/components/AppContainer/App/App'
import * as serviceWorker from './serviceWorker';
import App from './App';

ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
