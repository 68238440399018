import React from 'react'

import './Copyright.scss'

const  Copyright = () => {
    return(
        <div class="footer-copyright">
            <div class="footer-copyright__container">
                {`© 2014 - ${new Date().getFullYear()} | ENGTECK | Todos os Direitos Reservados - Telefone: (011) 96640-7607`}
            </div>
        </div>
    )
}

export default Copyright