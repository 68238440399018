import React from 'react';

import './Footer.scss'
import Copyright from '../ui/Copyright';

const Footer = () => (
  <footer class="page-footer font-small cyan darken-3">
    <Copyright />
  </footer>
);

export default Footer;
