import React, { Component } from 'react';

import ContentMain from '../ui/ContentMain';
import Container from 'react-bootstrap/Container';
import './Contatos.scss'
import axios from "axios";
import ModalCust from '../ui/ModalCust';

class Contatos extends Component {
    constructor(props) {
        super(props)
        this.state = {
            first_name: "",
            email: "",
            Sub_ject: "",
            telephone: "",
            comments: "",
            showModal: false
        }

        this.handleChange = this.handleChange.bind(this)
        this.clearFields = this.clearFields.bind(this)
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
        console.log(event.target.name, event.target.value)
    }

    async handleSubmit(e){
        e.preventDefault();
        console.log("event:", e.target)
        
        let formData =  {
            first_name: this.state.first_name,   
            email: this.state.email,
            Sub_ject: this.state.Sub_ject,
            comments: this.state.comments,
            telephone: this.state.telephone
        }
        
        try {
            await axios.post('http://engteck.com.br/sendmail2.php', formData)
            this.clearFields()
            this.setState({showModal: true})
        } catch (error) {
            
        } finally {

        }
         
        // axios.post('http://engteck.com.br/sendmail2.php', formData)
        //     .then(function (response) {
        //         console.log(response);
        //         this.clearFields()
        //     })
        //     .catch(function (error) {
        //         console.log(error);
        // });
    }

    handleNotification() {
    }    

    
    clearFields () {
        console.log("limpou...")
        this.setState({
            first_name: "",
            email: "",
            Sub_ject: "",
            telephone: "",
            messsage: "",
            showModal: false
        })
    }

    render() {
        return(
            <Container>
                <form 
                    class="text-center border border-light contatos-form"
                    id="contact-form"
                    onSubmit={this.handleSubmit.bind(this)} method="POST">
            
                    <p class="h2 mb-4">Entre em Contato</p>
            
                    <input 
                        name="first_name"
                        required
                        type="text"
                        id="defaultContactFormName"
                        class="form-control mb-4"
                        placeholder="Nome"
                        value={this.state.first_name}
                        onChange={this.handleChange} />
            
                    <input
                        name="email"
                        required
                        type="email"
                        id="defaultContactFormEmail"
                        class="form-control mb-4"
                        placeholder="E-mail"
                        value={this.state.email}
                        onChange={this.handleChange} />
                    
                    <input 
                        name="telephone"
                        type="tel"
                        id="defaultContactFormtelephone"
                        class="form-control mb-4"
                        placeholder="Telefone"
                        value={this.state.telephone}
                        onChange={this.handleChange} />
            
                    <label>Assunto</label>
                    <select
                        name="Sub_ject"
                        value={this.state.Sub_ject}
                        class="browser-default custom-select mb-4"
                        onChange={this.handleChange}>
                        <option value="" disabled>Escolha a opção</option>
                        <option value="SAC" selected>SAC</option>
                        <option value="Contratar Serviços">Contratar Serviços</option>
                        <option value="Sugestões">Sugestões</option>
                        <option value="Duvidas">Duvidas</option>
                    </select>
            
                    <div class="form-group">
                        <textarea
                            required
                            name="comments"
                            class="form-control rounded-0"
                            id="exampleFormControlTextarea2"
                            rows="3"
                            placeholder="Mensagem"
                            value={this.state.messsage}
                            onChange={this.handleChange}>
                        </textarea>
                    </div>
            
                    {/* <div class="custom-control custom-checkbox mb-4">
                        <input type="checkbox" class="custom-control-input" id="defaultContactFormCopy" />
                        <label class="custom-control-label" for="defaultContactFormCopy">Envie-me uma cópia desta mensagem</label>
                    </div> */}
            
                    <button
                        class="btn btn-info btn-block"
                        type="submit">
                        Enviar
                    </button>
            
                </form>
                <ModalCust
                    show={this.state.showModal}
                    onHide={() => this.setState({showModal: false})}>
                </ModalCust>
                <div class="bg-bot">
                    <div class="main">
                        <div class="container_12">
                            <div class="wrapper">
                                <p align="center" class="Estilo7">ENDEREÇO </p>
                                <p align="center" class="tdate-1">R Calandra, 85, Vila Germinal, Sao Paulo, SP, CEP 02275-000, Brasil</p>
                                <p align="center"><span class="Estilo7">LOCALIZAÇÃO</span></p>
                                <p align="center">
                                    <iframe
                                        className="contatos__map"
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3659.803402771215!2d-46.595101684468695!3d-23.467554964021176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cef5d80625dbe3%3A0xeb6c5d629c5abf37!2sR.+Calandra%2C+85+-+Vila+Germinal%2C+S%C3%A3o+Paulo+-+SP!5e0!3m2!1spt-BR!2sbr!4v1498689183428"
                                        frameborder="0"
                                        allowfullscreen>
                                    </iframe>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        )
    }
} 

export default Contatos;
