import React from 'react'

import './Divider.scss'

const Divider = ({ title, className }) => {
    return(
        <div className={`title ${className}`}>
            <div className="title-row">
                <div className="bar-container">
                    <div className="bar"></div>
                </div>
                <div className="text">
                    { title }
                </div>
                <div className="bar-container">
                    <div className="bar"></div>
                </div>
            </div>
        </div>       
    )
}

export default Divider
