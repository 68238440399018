import React from 'react'
import Carousel from 'react-bootstrap/Carousel'

import eletricidade from '../../assets/images/eletricidade.jpg'
import './Carousel.scss'

export class ControlledCarousel extends React.Component {
    constructor(props, context) {
      super(props, context);
  
      this.handleSelect = this.handleSelect.bind(this);
  
      this.state = {
        index: 0,
        direction: null,
      };
    }
  
    handleSelect(selectedIndex, e) {
      this.setState({
        index: selectedIndex,
        direction: e.direction,
      });
    }
  
    render() {
      const { index, direction } = this.state;
  
      return (
        <Carousel
          activeIndex={index}
          direction={direction}
          onSelect={this.handleSelect}
          interval={2000}
          slide={true}
          pauseOnHover={false}
        >
          <Carousel.Item>
            <div className="carousel-img1"></div>
            <Carousel.Caption>
              {/* <h2 className="carousel__text">Inovação</h2>
              <p className="carousel__text">Sempre inovando</p> */}
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carousel-img2"></div>  
            <Carousel.Caption>
              {/* <h2 className="carousel__text">Técnologia</h2>
              <p className="carousel__text">Tecnologia é nosso DNA</p> */}
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carousel-img3"></div>
            <Carousel.Caption>
              {/* <h2 className="carousel__text">Qualidade</h2>
              <p className="carousel__text">
                Qualidade é nosso prioridade
              </p> */}
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carousel-img4"></div>
            <Carousel.Caption>
              {/* <h2 className="carousel__text">Qualidade</h2>
              <p className="carousel__text">
                Qualidade é nosso prioridade
              </p> */}
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      );
    }
  }

  
//   render(<ControlledCarousel />);