const strings = {
    home: {
        descEmpresa: 'Empresa fundada em julho de 2013, com uma equipe altamente qualificada e em parceria com os melhores fornecedores, a empresa vem se consolidando no segmento elétrico.',
        descServicos: 'Montagem de painéis elétricos de baixa tensão. Os produtos são rigorosamente testados em atendimento aos requisitos das normas NR10 e IEC 60439.',
        descParceiros: 'O Sucesso da Engteck está diretamente relacionado ao sucesso dos nosso parceiros e clientes, conheça algumas das organizações que apostam e confiam nas soluções que oferecemos:',
    },
    empresa: {
        descri01: 'Empresa fundada em julho de 2013, com uma equipe altamente qualificada e em parceria com os melhores fornecedores, a empresa vem se consolidando no segmento elétrico.',
        descri02: 'A ENGTECK é uma empresa Brasileira dedicada à fabricação de painéis e equipamentos elétricos em baixa tensão para uso comercial, residencial e industrial, entre outros.',
        descri03: 'Estamos capacitados à atender todos métodos definidos em seus projetos, temos uma equipe preparada para desenvolver e dar respaldo à todas as especificações das principais normas vigentes da Associação Brasileira de Normas Técnicas – ABNT.',
        nossaVisao01: 'Oferecer soluções personalizadas, por entender que cada projeto é único e merece abordagem diferente, proporcionando inclusive otimização de investimentos.',
        politica: 'Garantir de forma continua a melhoria no fornecimento de soluções, de acordo com as especificações, prazos e necessidades dos clientes.'

    }
}

export default strings;