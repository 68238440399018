import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Collapse from 'react-bootstrap/Collapse';
import eletricidade from '../../assets/images/eletricidade.jpg';
import img_home01 from '../../assets/images/home_01.jpg';
import img_home02 from '../../assets/images/home_02.jpg';

import { ControlledCarousel } from '../ui/Carousel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './Home.scss';
import strings from '../../assets/strings';
import { FaRegBuilding, FaCubes, FaHandshake, FaCogs } from 'react-icons/fa';
import { IoLogoWhatsapp } from 'react-icons/io';
import Divider from '../Divider/Divider';
import Button from '@material-ui/core/Button';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Grid } from '@material-ui/core';
import { equipamentos } from '../../enums/equipamentos';

const MENU_ITEMS = [
  {
    to: `galeria/${equipamentos.capacitor}`,
    label: 'Painéis de Distribuição',
  },
  { to: `galeria/${equipamentos.bomba}`, label: 'Painéis de Bomba' },
  { to: `galeria/${equipamentos.pressurizacao}`, label: 'Pressurização' },
  { to: `galeria/${equipamentos.geral}`, label: 'Paineis TTA' },
  { to: `galeria/${equipamentos.geral}`, label: 'Quadro Geral (QGBT)' },
  { to: `galeria/${equipamentos.ditribuicao}`, label: 'Painéis de Automação' },
];

// to={`/repository/${encodeURIComponent(repository.name)}`}

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = { open: false };
  }

  render() {
    return (
      <Container>
        <Row>
          <Col md={12}>
            <ControlledCarousel />
          </Col>
        </Row>
        <div className='container text-center'>
          <div className='row thf-mt-3'>
            <div className='col-sm-4 home__card'>
              <FaRegBuilding className='home-icon' />
              <h1 className='home__subtitle'>A Empresa</h1>
              <p>{strings.home.descEmpresa}</p>
              <Link to='/empresa' className='home__link'>
                Veja Mais
              </Link>
            </div>
            <div className='col-sm-4 home__card'>
              <FaCubes className='home-icon' />
              <h1 className='home__subtitle'>Serviços</h1>
              <p>{strings.home.descServicos}</p>
              <Link to='/servicos' className='home__link'>
                Veja Mais
              </Link>
            </div>
            <div className='col-sm-4 home__card'>
              <FaHandshake className='home-icon' />
              <h1 className='home__subtitle'>Parceiros</h1>
              <p>{strings.home.descParceiros}</p>
              <Link to='/parceiros' className='home__link'>
                Veja Mais
              </Link>
            </div>
          </div>

          <Divider title={'Soluções'} className='mt-4' />
          {/* Soluções */}
          <Grid container justify='center' alignItems='center'>
            {MENU_ITEMS.map((item) => {
              return (
                <Navbar.Brand href='#home'>
                  <Link clLinkss='navbar-brand' to={item.to}>
                    <div className='home-solcucoes__item col-md-3 col-sm-12 home__card mt-3'>
                      <FaCogs className='home-solcucoes__icon' />
                      <span className='thf-ml-2'>{item.label}</span>
                    </div>
                  </Link>
                </Navbar.Brand>
              );
            })}
          </Grid>

          {/* <div className="row thf-mt-3 thf-mb-4">
              <div className="col-sm-12 home__card">
              <MdContactPhone  className="home-icon home-icon__primary"/>
              <h3 className="home__subtitle">(11) 2609 - 6045</h3>
              </div>
            </div> */}
        </div>
      </Container>
    );
  }
}

export default Home;
