import React from 'react';
import HeaderNav from './HeaderNav/HeaderNav';
import './Header.scss'

const Header = () => {
  return (
    <header className="header">
      <HeaderNav/>
    </header>
  );
};

export default Header;
