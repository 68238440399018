import React, { Component } from 'react';

import './Galeria.scss';
import imagesGallery from '../../assets/images/images.js';
import { Container, Grid, Button, Zoom, Typography } from '@material-ui/core';

const IMAGES = [
  {
    type: 'neve',
    src: 'https://mdbootstrap.com/img/Photos/Vertical/mountain1.jpg',
    alt: 'Card image cap',
  },
];

class Galeria extends Component {
  constructor(props) {
    super(props);
    this.state = {
      animation: true,
      images: [],
      imagesGroups: [],
      imagesFiltered: [],
      equipamento: '',
    };
  }

  // const repoName = decodeURIComponent(match.params.repository);
  componentWillMount() {
    this.setState({ equipamento: this.props.match.params.equipamento });
    this.mountGalleryImages();
  }

  componentDidMount() {
    this.handleFilter(this.state.equipamento);
  }

  mountGalleryImages() {
    let gallery = [];
    let imagesGroups = Object.keys(imagesGallery.gallery);
    imagesGroups.forEach((group) => {
      imagesGallery.gallery[group].forEach((item) => {
        gallery.push({ type: group, src: item, alt: group });
      });
    });
    this.setState({
      images: gallery,
      imagesGroups: imagesGroups,
      imagesFiltered: gallery,
    });
  }

  handleFilter(filter) {
    console.log(filter);
    let imagesFiltered;

    this.setState({ imagesFiltered: false });
    if (filter === 'todas') {
      this.setState({ imagesFiltered: this.state.images });
    } else {
      imagesFiltered = this.state.images.filter((item) => item.type === filter);
      this.setState({ imagesFiltered: imagesFiltered, animation: true });
    }
  }

  render() {
    const { imagesGroups, imagesFiltered, animation } = this.state;
    return (
      <div>
        <Grid container justify='center' alignItems='center'>
          <Typography variant='h6' color='textPrimary'>
            Filtrar por:{' '}
          </Typography>
          {imagesGroups.map((item) => {
            return (
              <Grid item className='buttons__container'>
                <Button
                  color='primary'
                  variant='outlined'
                  key={item}
                  onClick={(filter) => this.handleFilter(item)}
                >
                  {item}
                </Button>
              </Grid>
            );
          })}
        </Grid>
        <Container variant='Card' maxWidth='md'>
          <div class='gallery' id='gallery'>
            {imagesFiltered.map((item) => {
              return (
                <Zoom in={animation} timeout={1000}>
                  <div class='mb-3 pics animation all 2'>
                    <img class='img-fluid' src={item.src} alt={item.alt} />
                  </div>
                </Zoom>
              );
            })}
          </div>
        </Container>
      </div>
    );
  }
}

export default Galeria;
